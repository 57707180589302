import Zooming from 'zooming'

document.addEventListener('DOMContentLoaded', function () {
  const zooming = new Zooming({
  });
  zooming.listen('.zoom-js img');
});

//
$ = window.$;

$(function(){

	//use foundation
	// $(document).foundation();

	//過去記事の画像切れ削除
	$('.entry img').each(function(){
		if($(this).width() < 40) $(this).remove();
	});

	//購入数増減
	$('.action-card__quant').each(function(){
		this._num = Number($(this).children('input').val());
	}).on('changeQuant',function(event,changeNum){
		this._num += changeNum;
		$(this).children('input').val(this._num);
		return this;
	});
	$('.action-card__quant__increase').on('click',function(){
		$(this).parent('.action-card__quant').trigger('changeQuant',[1]);
		return this;
	});
	$('.action-card__quant__decrease').on('click',function(){
		$(this).parent('.action-card__quant').trigger('changeQuant',[-1]);
		return this;
	});

	//メニュー展開
	$('#globalMenu').each(function(index, element) {
		var $target = $('#globalMenu,#globalNavLists');
		$(this).on('click',function(event){
			if($target.hasClass('open')){
				$target.removeClass('open');
			}else{
				$target.addClass('open');
				event.stopPropagation()
			}
		});
	});
	$('body').on('click',function(event){
		if($('#globalMenu').hasClass('open')){
			$('#globalMenu,#globalNavLists').removeClass('open');
		}
	});
	if($('html.touch').length && $('#globalMenu').hasClass('open')){
		$('#globalMenu').trigger('click');
	}

	//unique page
	if($("body.onlineshop").length){
		var itemList = document.querySelector('#itemList');
		var thumb = document.querySelectorAll('.thumb');
		var msnry_param = {
			itemSelector: '.item-list__thumb',
			percentPosition: true,
			//columnWidth: 200,
			gutter: 0,
			isFitWidth: true
		};
		//masorny
		imagesLoaded( thumb )
			.on( 'progress', function( instance, image ) {
				// var result = image.isLoaded ? 'loaded' : 'broken';
				//console.log( 'image is ' + result + ' for ' + image.img.src );
				$(image.img).addClass('show');
				var msnry = new Masonry( itemList, msnry_param );
			})
			.on( 'always', function( instance ) {
				//comp
				$('.thumb').addClass('show');
			});

	}

	//unique page
	if(
    $("body#mail-order").length ||
    $("body#order").length
  ){
		var vars = [], array = "";
		var url = window.location.search;
    if(url === '') return this;
		var hash  = url.slice(1).split('&');
		var max = hash.length;
    for (var i = 0; i < max; i++) {
        array = hash[i].split('=');
        vars.push(array[0]);
        vars[array[0]] = array[1];
    }
    if(typeof vars.item === 'undefined') return this;
		var input_text = decodeURIComponent(vars.item);
		$('input#title').val(
			input_text
				.replace(/\+/g, ' ')
				.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;') + 'について'
		);
	}

  if(
    $("body#payment").length
  ){
    $('#delivery-form').hide();
		$('input[name="発送先"]').on('click', function(e){
      if(e.target.checked === true && e.target.defaultValue === '別の発送先を指定する'){
        $('#delivery-form').show();
      }
      if(e.target.checked === true && e.target.defaultValue === 'お客様情報と同じ'){
        $('#delivery-form').hide();
      }
    });
	}

});
